import "bootstrap/dist/css/bootstrap.min.css";
import "bootstrap/dist/js/bootstrap.bundle.min";
import _, { debounce } from "lodash";
import React, { useCallback, useContext, useEffect, useState, useRef } from "react";
import { Button,Dropdown, Form } from "react-bootstrap";
import "react-datepicker/dist/react-datepicker.css";
import {
  getauditData,
  getAuditFiles,
  getAuditRows,
  getAuditRowsByFileName,
  getSearchData,
  getSearchRecommendations,
  recall_episode,
  getUsers,
  episodeAssignedUsers,
  flag_and_unflag
} from "../../api/Service";
import refresh from "../../assests/reload.png";
import SearchIcon from "../../assests/search-lg.png";
import { FilterLines } from "../../assests/svgImages/Images";
import CloseFilter from "../../assests/x-close.png";
import { extractFileName } from "../../utils/util";
import WorkFlowFilters from "../filters";
import "./Navbar.css";
import { Spinner } from "../../common/Helper";
import InfiniteScroll from "../../../codercompanion/components/infinite-scroll";
import { Link, useNavigate } from "react-router-dom";
import axiosInstance from "../../../../../axiosInterceptor";
import { toast, ToastContainer } from "react-toastify";
import AssignedToModal from "../filters/AssignedToModal";
import userSearchIcon from "../../assests/search-icon.png";
import avatar from "../../assests/Avatar.png"
import assgineTickIcon from "../../assests/assgine-tick.png";
import downArrow from "../../assests/downarrow.png"

const showLoadingText = () => {
  return(
    <div className="loading-assigne-modal-overlay-fade z-3">
    <div className="loading-modal-content-section">
        <p>Assigning is in progress, Please wait...</p>
        <Spinner />
    </div>
  </div>
  )
}

function Navbar({
  updatedAuditRows,
  handleRefreshClick,
  setAuditDetails,
  setIsRowsLoading,
  isRowsLoading,
  getAuditRowsByFilter,
  setHasMore,
  setAuditRows,
  setflagAuditRows,
  flagauditRows,
  getAuditRowsData,
  setPage,
  editFlageData,
  selectedEpisodeRows,
  setSelectedEpisodeRows,
  isAllEpisodesSelected,
  setIsAllEpisodesSelected,
  recall_page,
  setRecall_page,
}) {
  const [showModal, setShowModal] = useState(false);
  const [searchEpisodeIdentifier, setSearchEpisodeIdentifier] = useState("");
  const [recommendations, setRecommendations] = useState([]);
  const [isRecommendationsLoading, setIsRecommendationsLoading] =
    useState(false);
  const [auditFiles, setAuditFiles] = useState([]);
  const [isRecallLoading,setIsRecallLoading]=useState(false);
  const navigate=useNavigate();
  const [appliedFilters, setAppliedFilters] = useState({
    insurer_identifier: [],
    priority_value: [],
    status: [],
    file_type: [],
    coder_name: [],
    admission_date: null,
    separation_date: null,
    header_id: [],
  });
  const [currentPage, setCurrentPage] = useState(1);
  const [hasMany, setHasMany] = useState(true);
  const [isLoading, setIsLoading] = useState(false);
  const [selectAllFiles, setSelectAllFiles] = useState(false);

  const [filtersObj, setFiltersObj] = useState({
    fileType: [],
    priorityValue: [],
    status: [],
    assigned_to: [],
    assigned_by: [],
    case_mix_speciality: [],
    insurerIdentifier: [],
    codedBy: [],
    fromAdmissionDate: "",
    toAdmissionDate: "",
    fromSeparationDate: "",
    toSeparationDate: "",
    length_of_stay: []
  });
  const assginedUserId = JSON.parse(sessionStorage.getItem("userId"));
  const [openUserAssgineeModal, setOpenUserAssgineeModal] = useState(false);
  const [openConfirmAssgineModal, setOpenConfirmAssgineModal] = useState(false);
  const [loadingUsers, setLoadingUsers] = useState(false);
  const [searchAssgineeUserName, setSearchAssgineeUserName] = useState("");
  const [addAssignReason , setAddAssignReason] = useState("");
  const [getAllUsers, setGetAllUsers] = useState([]);
  const [assgineCurrentPage, setAssgineCurrentPage ] = useState(1);
  const [selectedAssgineeUser, setSelectedAssgineeUser] = useState(null);
  const [hasMoreUsers, setHasMoreUsers] = useState(true);
  const [openAccordion, setOpenAccordion] = useState([]);
  const [isAssigneLoading, setIsAssigneLoading] = useState(false);

// const [noUsersMessage, setNoUsersMessage] = useState("")

  const toggleModal = () => {
    setShowModal(!showModal);
    setOpenAccordion([]);
    document.body.style.overflowY = "auto";
  }
  const fetchRecommendations = useCallback(debounce((identifier) => {
      setIsRecommendationsLoading(true);
      getSearchRecommendations(identifier,flagauditRows)
        .then((res) => {
          if(res.message){
            toast.error(res.message)
          }
          const { audited_rows } = res.data;
           // console.log("dffdf", audited_rows)
          setRecommendations(audited_rows);
        })
        .catch((err) =>
         console.log("Search Recommendations Error::", err.message)
        )
        .finally(() => setIsRecommendationsLoading(false));
    }, 500),
  
    [flagauditRows]
  
  );

  const fetchUsers = useCallback(debounce((userName) => {
    setLoadingUsers(true);
    setHasMoreUsers(true);
    getUsers(userName, assgineCurrentPage)
      .then((res) => {
        if(res.message){
          toast.error(res.message);
        }
        const { users } = res.data;
        setGetAllUsers(users);
      })
      .catch((err) =>{
        console.log("No Users Found::", err.message)
        // setNoUsersMessage(err.message)
        setGetAllUsers([]);
      }
      )
      .finally(() => setLoadingUsers(false));
  }, 500),
  []
);

  const handleSearchRecommendClick = (rowId) => {
    setSearchEpisodeIdentifier(rowId);
    getSearchResults(rowId);
  };
  const handleSearch = (event) => {
    const { value } = event.target;
    setSearchEpisodeIdentifier(value);
    if (value) {
      fetchRecommendations(value);
    } else {
      setRecommendations([]);
      setIsRecommendationsLoading(false);
    }
  };


  const searchAssgineUsers = (event) => {
    const {value} = event.target
    setSearchAssgineeUserName(value);
    fetchUsers(value);
  }
  
  const getSearchResults = (searchData) => {
    //console.log('searchData',searchData);
    setIsRowsLoading(true);
    getSearchData(searchData,flagauditRows)
      .then((response) => {
        //console.log('UpdatedAudit',response.data.audited_rows);
        updatedAuditRows(response.data.audited_rows);
        setAuditDetails({});
        setSearchEpisodeIdentifier("");
        setHasMore(false);
        setRecommendations([]);
      })
      .catch((err) =>console.log("Search Results Error::", err.message))
      .finally(() => {
        setIsRowsLoading(false);
      });
  };

  const handleFilterClick = async (latestFilters = {}) => {
    const filters = { ...latestFilters, header_id: appliedFilters?.header_id };
    setAppliedFilters(filters);
    getAuditRowsByFilter(filters);
    setSelectedEpisodeRows([]);
    setIsAllEpisodesSelected(false);
  };
  const handleKeyPress = (event) => {
    if (event.key === "Enter") {
      getSearchResults(searchEpisodeIdentifier);
    }
  };

  const arraysHaveSameElements = (arr1, arr2) => {
    if (arr1.length !== arr2.length) {
      return false;
    }
    const set1 = new Set(arr1);
    const set2 = new Set(arr2);
    for (let item of set1) {
      if (!set2.has(item)) {
        return false;
      }
    }
    return true;
  };

  function handleSelectChange(event, fileName) {
    // console.log("CHANGE::", fileName);
    const { value } = event.target;
    let selectedFields = [];
    let selectedFileNames = [];
    if (event.target.checked) {
      selectedFields = [...appliedFilters.header_id, value];
      selectedFileNames = [...appliedFilters.file_type, ...fileName];
    } else {
      selectedFields = [...appliedFilters.header_id];
      selectedFileNames = [...appliedFilters.file_type];
      const indexOfID = selectedFields.indexOf(value);
      const indexOfFiletype = selectedFileNames.indexOf(...fileName);
      selectedFields.splice(indexOfID, 1);
      selectedFileNames.splice(indexOfFiletype, 1);
      // console.log("CHANGE::1", selectedFields);
    }
    const filters = {
      ...appliedFilters,
      header_id: selectedFields,
      file_type: selectedFileNames,
    };
    const allLoadIds = [];
    const allFileNames = [];
    auditFiles.forEach((file) => {
      allLoadIds.push(file.header_id);
      allFileNames.push((file.file_name));
    });
    // console.log("IDS::::", allLoadIds, selectedFields);

    if (
      arraysHaveSameElements(allLoadIds, selectedFields) &&
      arraysHaveSameElements(allFileNames, selectedFileNames)
    ) {
      setSelectAllFiles(true);
    } else {
      setSelectAllFiles(false);
    }
    setAppliedFilters(filters);
    getAuditRowsByFilter(filters);
  }
  const handleBreadCrumbClose = (key) => {
    handleFilterClick({ ...appliedFilters, [key]: [] });

    if(key === "priority_value"){
      setFiltersObj({...filtersObj, "priorityValue": []})
    }
    if(key === "insurer_identifier"){
      setFiltersObj({...filtersObj, "insurerIdentifier": []})
    }
    if(key === "status"){
      setFiltersObj({...filtersObj, [key] : []})
    }

    // setFiltersObj({ [key]: [] })
  };

  const handleSelectAllCheckboxes = (e) => {
    const { checked } = e.target;
    let selectedFields = [];
    let selectedFileNames = [];
    setSelectAllFiles(checked);
    if (checked) {
      // console.log("ALL:::", e.target.checked, auditFiles);
      auditFiles.forEach((file) => {
        selectedFields.push(file.header_id);
        selectedFileNames.push((file.file_name));
      });
    } else {
      selectedFields = [];
      selectedFileNames = [];
    }
    const filters = {
      ...appliedFilters,
      header_id: selectedFields,
      file_type: selectedFileNames,
    };
    //console.log("FILTERS::::", filters);
    setAppliedFilters(filters);
    getAuditRowsByFilter(filters);
  };

  // key is to match with appliedFilters keys
  const breadCrumbs = [
    { label: "Status", key: "status" },
    { label: "Priority Value", key: "priority_value" },
    { label: "Insurer Identifier", key: "insurer_identifier" },
  ];
  //console.log("APPLIED:::", appliedFilters, auditFiles);
  const getData = async(page, load_more) => {
    try {
      setIsLoading(true);
      const response = await getAuditFiles(page);
      //console.log("AUDIT_FILES:::", response);
      if (load_more) {
        setAuditFiles([...auditFiles, ...response]);
      } else {
        setAuditFiles(response);
      }
      setHasMany(true);
      const newPage = currentPage + 1;
      setCurrentPage(newPage);
    } catch (error) {
     console.log("Error fetching audit files:", error);
      setHasMany(false);
      if (!load_more) {
        setAuditFiles([]);
      }
    } finally {
      setIsLoading(false);
    }
  };
  useEffect(() => {
    getData(1);
  }, []);
  //console.log('allauditfiles',flagauditRows);
  
  const assginToHandler = (e) => {
    if(selectedEpisodeRows && !selectedEpisodeRows.length > 0){
      toast.info("Please Select Some Episodes")
    } else{
      if(selectedAssgineeUser === null){
          toast.info("Please Select User");
      } else{
        setOpenUserAssgineeModal(false);
        setOpenConfirmAssgineModal(true)
        setSearchAssgineeUserName("");
      }
    }
  }

  const handleAssgintoUsers = (data) => {
    if(selectedEpisodeRows && !selectedEpisodeRows?.length > 0){
      toast.info("Please Select Some Episodes")
    }
    if(selectedEpisodeRows && selectedEpisodeRows?.length > 0){
      if (selectedAssgineeUser?.user_id === data?.user_id) {
        return;
      }
      setSelectedAssgineeUser(data);
    }
  };


  const assgineeModalHandle = (e) => {
    setOpenUserAssgineeModal((prev) => {
      const modalIsOpened = !prev;
      if (modalIsOpened) {
        setHasMoreUsers(true);
        setLoadingUsers(true);
        getUsers(searchAssgineeUserName, assgineCurrentPage)
          .then((res) => {
            if (res.message) {
              toast.error(res.message);
            }
            const { users } = res.data;
            setGetAllUsers(users);
          })
          .catch((err) => {
            console.log("No Users Found::", err.message);
          })
          .finally(() => setLoadingUsers(false));
      } else {
        setGetAllUsers([])
        setSearchAssgineeUserName("");
        setSelectedAssgineeUser(null)
        setAssgineCurrentPage(1)
      }
      return modalIsOpened;
    });
  };

  
  const getMoreUsers = async(assgineCurrentPage, load_more) => {
    try {
      setLoadingUsers(true);
      const response = await getUsers(searchAssgineeUserName, assgineCurrentPage);
      const { users } = response.data;
      const mergedUsers = [
        ...new Map([...getAllUsers, ...users].map(item => [item.user_id, item])).values()
      ];
      if (load_more) {
        setGetAllUsers(mergedUsers);
      } else {
        setGetAllUsers(users);
      }
      setHasMoreUsers(true);
      const newPage = assgineCurrentPage + 1;
      setAssgineCurrentPage(newPage);
    } catch (error) {
      console.log("Error fetching users:", error);
      setHasMoreUsers(false);
      setAssgineCurrentPage(1)
      if (!load_more) {
        setGetAllUsers([]);
      }
    } finally {
      setLoadingUsers(false);
    }
  };
  
  useEffect(() => {
    if(openUserAssgineeModal){
        getMoreUsers(1, true)
    }
  }, [openUserAssgineeModal]);
  

const episodeUserAssignments =useCallback(() => {
    setIsAssigneLoading(true);
    episodeAssignedUsers(selectedEpisodeRows, selectedAssgineeUser?.user_id, assginedUserId, addAssignReason, isAllEpisodesSelected, filtersObj, recall_page, appliedFilters.header_id)
      .then((repo) => {
        if (repo?.response?.status === 401) {
            const errMessage = (repo?.response?.data?.message.includes("TypeError: Cannot read properties of undefined"))
              ? "Assign failed"
            : repo?.response?.data?.message || "An unknown error occurred";
            toast.error(errMessage);
            console.log("Episode assigned failed::", repo?.response?.data?.message);
            setGetAllUsers([])
            setSearchAssgineeUserName("");
            setSelectedAssgineeUser(null)
            setAssgineCurrentPage(1)
        }else{
        toast.success(repo.message);
        setSelectedEpisodeRows([]);
        setIsAllEpisodesSelected(false);
        window.location.reload();
        setHasMoreUsers(true)
      }
    })
      .catch((err) =>{
        const errMessage = err.message.includes("Cannot read properties") ? "Assign failed" : err.message 
        toast.error(errMessage);
        console.log("Episode assigned failed::", err.message);
        setGetAllUsers([])
        setSearchAssgineeUserName("");
        setSelectedAssgineeUser(null)
        setAssgineCurrentPage(1)
      }
    )
    .finally(() => setIsAssigneLoading(false));
}, [selectedEpisodeRows, selectedAssgineeUser, assginedUserId, addAssignReason, isAllEpisodesSelected, filtersObj]);

  const handleAssignReason = () => {
    setOpenConfirmAssgineModal(!openConfirmAssgineModal);
    episodeUserAssignments()
    }

  const cancelAssignReason  =() => {
    setOpenConfirmAssgineModal(false)
  }
  const handleFlagUnFlag=()=>{
    console.log(appliedFilters.header_id,'newobj');
    if(selectedEpisodeRows && !selectedEpisodeRows?.length > 0){
      toast.info("Please Select Some Episodes")
    }else if(!flagauditRows){
      flag_and_unflag(selectedEpisodeRows, selectedAssgineeUser?.user_id, assginedUserId, addAssignReason, isAllEpisodesSelected, filtersObj, !recall_page,appliedFilters.header_id)
      .then((res)=>{
        // toast.success(res.data.message);
        window.location.reload()
      })
      .catch((err)=>{
        toast.error(err.message);
        console.log("Episode assigned failed::", err.message)
      })
    }else{
    flag_and_unflag(selectedEpisodeRows, selectedAssgineeUser?.user_id, assginedUserId, addAssignReason, isAllEpisodesSelected, filtersObj, !recall_page,appliedFilters.header_id)
      .then((res)=>{
        //toast.success(res.data.message);
        window.location.reload()
      })
      .catch((err)=>{
        toast.error(err.message);
        console.log("Episode assigned failed::", err.message)
      })
    }
  }
  useEffect(() => {
    if (flagauditRows) {
      getAuditRowsData(1, true);
    }
  }, [flagauditRows]);
  return (
    <div className="navbar">
      <ToastContainer autoClose={5000}/>
      <div className="navbar-left-section">
        <input
        className="navbar-left-checkbox"
          type="checkbox"
          checked={selectAllFiles}
          onChange={handleSelectAllCheckboxes}
        />
        <Dropdown className="hcp-file-dropdown-webworkflow">
          <Dropdown.Toggle id="dropdown-basic">Select All</Dropdown.Toggle>
          <Dropdown.Menu className="webworkflow-dropdown-menu">
              <InfiniteScroll
                className="load-filters"
                hasData={hasMany}
                loadMore={() => getData(currentPage, true)}
                isLoading={isLoading}
              >
                <div>
                  {auditFiles.map((item) => (
                    <Form.Check
                      key={item.header_id}
                      type="checkbox"
                      label={`${item.start_date} - ${
                        item.end_date
                      } (${item.file_name.map((eachFileItem)=>(eachFileItem))})`}
                      value={item.header_id}
                      checked={
                        selectAllFiles || appliedFilters.header_id?.includes(String(item.header_id))
                       
                      }
                      onChange={(e) =>
                        handleSelectChange(e, (item.file_name.map((eachFileItem)=>(eachFileItem))))
                      }
                      // style={{ width: "300px" }}
                    />
                  ))}
                </div>
              </InfiniteScroll>
          </Dropdown.Menu>
        </Dropdown>

        <div className="status-Filter-values d-flex gap-2">
          {breadCrumbs.map((breadCrumb) =>
            appliedFilters[breadCrumb.key]?.length > 0 ? (
              <div className={`${breadCrumb.key}-value d-flex`}>
                <p className="ellpsis">
                  {" "}
                  {breadCrumb.label}:
                  {appliedFilters[breadCrumb.key].map((items) => items + ",")}{" "}
                </p>
                <span
                  onClick={() => handleBreadCrumbClose(breadCrumb.key)}
                  className="close-filter-btn"
                >
                  <img src={CloseFilter} alt="Close" />
                </span>
              </div>
            ) : null
          )}
        </div>
      </div>
      <div className="navbar-right-section">
           
    {/* <div className="assgine-container">
    <span className="assgine-btn"  onClick={assgineeModalHandle}>Assign <img src={downArrow} height="6px" />
    </span>
    {openUserAssgineeModal && 
      (<div className="assgine-modal-section" ref={assgineModalRef}>
        <div className="assigne-search-section">
          <img className="assigne-search-icon" src={userSearchIcon} alt={"userSearchIcon"} />
          <input
            className="assgine-search-input"
            type="text"
            value={searchAssgineeUserName}
            onChange={searchAssgineUsers}
            placeholder="Search by Username or Email"
          />
        </div>
      <InfiniteScroll
        className="assgine-modal-body"
        hasData={hasMoreUsers}
        loadMore={() => getMoreUsers(assgineCurrentPage, true)}
        isLoading={loadingUsers}
      >
        { getAllUsers && getAllUsers?.length > 0 ? getAllUsers?.map((user, index) => {
          return (
          <div className={`assigne-user-section ${selectedAssgineeUser?.user_id === user?.user_id ? " active" : ""}`}
            onClick={() => handleAssgintoUsers(user)}>
          <div className="assigne-card">
              <p>
              <span className="assgine-user-name-icon">{user?.username ? user?.username[0] : ""}</span>
              </p>
              <p className="assigne-user-email-card">
              <span className="assign-username">{user?.username ? user?.username : "User not found"}</span>
              <span className="assign-email">{user?.email ? user?.email : ""}</span>
              </p>
          </div>
          <div className="">
            {selectedAssgineeUser?.user_id === user?.user_id ? <img src={assgineTickIcon} alt="" /> : ""}
          </div>
      </div>)})
      :
      !loadingUsers && <p className="no-usersfound">No Users Found</p>}
        </InfiniteScroll>
        <div className="assgine-actions-btn">
          <button className="assigne-save-btn" 
          onClick={assginToHandler}
          >
            Assign
          </button>
        </div>
      </div> )}
    </div> */}
    <button className="assgine-btn" onClick={handleFlagUnFlag}>{!flagauditRows?"Recall":"Reflag"}</button>
       <Dropdown
        className="dropdown-assgine"
        show={openUserAssgineeModal}
        onToggle={assgineeModalHandle}
        autoClose="outside"
      >
        <Dropdown.Toggle
          className={`assgine-btn ${openUserAssgineeModal ? 'open' : 'close'}`}>
            Assign
        </Dropdown.Toggle>
          <Dropdown.Menu className="assgine-modal-section">
          <Dropdown.Item  eventKey={openUserAssgineeModal} className="assgine-dropdown-item" >
              <div className="assigne-search-section">
                <img className="assigne-search-icon" src={userSearchIcon} alt={"userSearchIcon"} />
                  <input
                    className="assgine-search-input"
                    type="text"
                    value={searchAssgineeUserName}
                    onChange={searchAssgineUsers}
                    placeholder="Search by Username or Email"
                  />
                </div>
                <InfiniteScroll
                  className="assgine-modal-body"
                  hasData={hasMoreUsers}
                  loadMore={() => getMoreUsers(assgineCurrentPage, true)}
                  isLoading={loadingUsers}
                >
                { getAllUsers && getAllUsers?.length > 0 ? getAllUsers?.map((user, index) => {
                  return (
                    <div 
                      className={`assigne-user-section ${selectedAssgineeUser?.user_id === user?.user_id ? " active" : ""}`}
                      onClick={() => handleAssgintoUsers(user)}>
                      <div className="assigne-card">
                      <p>
                        <span className="assgine-user-name-icon">{user?.username ? user?.username[0] : ""}</span>
                      </p>
                      <p className="assigne-user-email-card">
                        <span className="assign-username">{user?.username ? user?.username : "User not found"}</span>
                        <span className="assign-email">{user?.email ? user?.email : ""}</span>
                      </p>
                      </div>
                      <div className="">
                        {selectedAssgineeUser?.user_id === user?.user_id ? <img src={assgineTickIcon} alt="" /> : ""}
                      </div>
                    </div>)})
                  :
                  !loadingUsers && <p className="no-usersfound">No Users Found</p>}
                </InfiniteScroll>
                <div className="assgine-actions-btn">
                  <button className="assigne-save-btn" 
                    onClick={assginToHandler}
                  >
                    Assign
                  </button>
                </div>
            </Dropdown.Item>
          </Dropdown.Menu>
        </Dropdown>
    {isAssigneLoading && showLoadingText()}        
    {openConfirmAssgineModal && <AssignedToModal handleAssignReason={handleAssignReason} cancelAssignReason={cancelAssignReason} addAssignReason={addAssignReason} setAddAssignReason={setAddAssignReason} />}
        <div className="search-bar">
          <img src={SearchIcon} alt="search-icon" className="search-icon" />
          <input
            className="audit-search"
            type="text"
            placeholder="Search by Patient Episode Identifier"
            onKeyDown={handleKeyPress}
            value={searchEpisodeIdentifier.toUpperCase()}
            onChange={handleSearch}
          />
          <div className="search-recommendations">
            {isRecommendationsLoading ? (
              <div className="loader">
                <Spinner />
              </div>
            ) : (
              recommendations?.map((item, idx) => (
                <li
                  key={idx}
                  onClick={() =>
                    handleSearchRecommendClick(item.episode_identifier)
                  }
                  className="search-recommendation"
                >
                   {/* {item.episode_identifier} */}
                   EpisodeID: {item.episode_identifier}
                </li>
              ))
            )}
          </div>
        </div>
        <div>

        {!flagauditRows && <button className="recall-btn"  onClick={()=>{
          localStorage.removeItem("breadcrumbClick")
          setSearchEpisodeIdentifier("");
          setRecommendations([]);
          setflagAuditRows(true);
          setRecall_page(true);
          setIsAllEpisodesSelected(false);
          setSelectAllFiles(false)
          setFiltersObj({
            fileType: [],
            priorityValue: [],
            status: [],
            assigned_to: [],
            assigned_by: [],
            case_mix_speciality: [],
            insurerIdentifier: [],
            codedBy: [],
            fromAdmissionDate: "",
            toAdmissionDate: "",
            fromSeparationDate: "",
            toSeparationDate: "",
            length_of_stay: [],
          })
          setAppliedFilters({
            insurer_identifier: [],
            priority_value: [],
            status: [],
            file_type: [],
            coder_name: [],
            admission_date: null,
            separation_date: null,
            header_id: [],
          });
          //sessionStorage.setItem("flagStatus",flagauditRows);
          // Clear existing rows and reset pagination
          //setIsRowsLoading(true);
          setAuditRows([]);
          setPage(1);
          setHasMore(true);
      
          // Fetch flagged rows
          // getAuditRowsData(1);
          recall_episode({page_number:1}).then((res) =>{
            //console.log("auditRows Res",res);
            if(res.data.message){
              toast.error(res.data.message);
            }
            //getAuditRows({});
            // setAuditRows(res.res.audited_rows)
            //setAuditRows(res.data.data.audited_rows); // Create a new array  
            // setflagAuditRows(true);
            
          }).catch((err) => {
            console.log("Error: " + err.message);
            
          })
        }}>View Recalled Episodes</button>}

{
          flagauditRows && 
         <div className="d-flex gap-2">
          <button className="recall-btn" onClick={()=>{
            window.location.reload();
            setRecall_page(false);
          }}>Back</button></div>
        }

      </div>
      {!flagauditRows && <button className="filter-wrapper" onClick={toggleModal}>
          <span style={{marginRight: "5px"}}>Filters</span>
          {/* <img src={filterlines} alt="filterlines" /> */}
          <FilterLines />
        </button>}
        {!flagauditRows && <span className="workflow-refresh-btn" onClick={handleRefreshClick}>
          {/* <strong>Refresh Data</strong> */}
          <span>
            <img src={refresh} alt="img" />
          </span>
        </span>}
      </div>


      {/*  Modal */}
      <WorkFlowFilters
        showModal={showModal}
        onApply={handleFilterClick}
        filtersObj={filtersObj}
        setFiltersObj={setFiltersObj}
        onCancel={toggleModal}
        openAccordion={openAccordion}
        setOpenAccordion ={setOpenAccordion}
      />
    </div>
  );
}

export default Navbar;
