import { BrowserRouter, useLocation } from "react-router-dom";
import "./App.css";
import { AuthProvider } from "./authContext/AuthContext";
import RoutesPage from "./routes/RoutesPage";
import Navbar from "./pages/navbar/Navbar";
import MainSidebar from "./pages/sidebar/MainSidebar";
import { useEffect, useState } from "react";
import { configData } from "./pages/navbar/config";
import ContextWrapper, { useChatContext } from "./context";
import { toast, ToastContainer } from "react-toastify";
import "react-toastify/dist/ReactToastify.css";
import ErrorModalPopup from "./ErrorModalPopup";
import axios from "axios";

export const isRelatedRoute =(path) => {
  return configData.find(
    (route) => route.path === path
  );
}

export const continueToken = async () => {
  const getRefreshToken= sessionStorage.getItem("refreshToken");
  const userEmail = sessionStorage.getItem("useremail");
    try {
      const payload = {
        REFRESH_TOKEN: getRefreshToken,      
        email: userEmail,  
      };
     const response = await axios.post("https://0hlxx4uqgk.execute-api.ap-southeast-2.amazonaws.com/refreshTocken", payload, {
        method: "POST",
        headers: {
          "Content-Type": "application/json",
        },
        });
     const newAcessToken = response?.data?.body?.new_access_token ?? "";
      if(!newAcessToken){
        return null
      }
      return newAcessToken
     } catch (error) {
      console.log("Refersh token error",error);
      return null
     }
  };
function App() {
  const location = useLocation();
  const {common}= useChatContext()
  const { mode, setMode, selectedCompanion, setSelectedCompanionState} = common;
  const [isModalVisible, setIsmodal] = useState(false);
  const [modalMessage, setModalMessage] = useState("");
  const [isRowClicked, setIsRowClicked] = useState(false);
  useEffect(() => {
   
    const activeRoute = isRelatedRoute(location.pathname)
    if (activeRoute) {
      setSelectedCompanionState(activeRoute.header);
    }
    const currentMode = sessionStorage.getItem("mode") ?? "DARK";
    setMode(currentMode);
  }, []);
  useEffect(() => {
    // const handleSessionExpired = (event) => {
    //   const errorMessage = event.detail.errorMessage;
    //   setModalMessage(errorMessage);
    //   setIsmodal(true);
    // };
    // window.addEventListener("tokenExpired", handleSessionExpired);
    // return () => {
    //   window.removeEventListener("tokenExpired", handleSessionExpired);
    // };

    const handleSessionExpired = (event) => {
      if (!sessionStorage.getItem("refreshToken") || sessionStorage.getItem("hasTokenFailed") === "true") return;
     
     const errorMessage = event.detail.errorMessage;
       setModalMessage(errorMessage);
    
        continueToken().then((newAcessToken) =>{
          if(!newAcessToken){
            setIsmodal(true)
            sessionStorage.setItem("hasTokenFailed","true")
          }else{
            sessionStorage.setItem("accessToken",newAcessToken)
            sessionStorage.removeItem("hasTokenFailed")
          }
        })
        .catch((error) =>{
          console.error("Error in refereshing token:", error)
          setIsmodal(true)
          sessionStorage.setItem("hasTokenFailed","true")
         
        })
       
    };

    window.addEventListener("tokenExpired", handleSessionExpired);
    return () => {
      window.removeEventListener("tokenExpired", handleSessionExpired);
    };
  }, []);
  const handleClose = () => {
    sessionStorage.clear();
    setIsmodal(false);
    window.location.replace("/login");
  };

  const isPowerAppRoute = location.pathname === "/powerapp";

  return (
    // <><AuthProvider>
    //   <ToastContainer position="top-center" autoClose={20000}/>
    //     <div className={mode !== "DARK" && "d-flex h-100"}>
    //       {mode === "DARK" ? (
    //         <Navbar
    //           setMode={setMode}
    //           mode={mode}
    //           setSelectedCompanionState={setSelectedCompanionState}
    //           selectedCompanion={selectedCompanion}
    //         />
    //       ) : (
    //         <MainSidebar
    //           setMode={setMode}
    //           mode={mode}
    //           setSelectedCompanionState={setSelectedCompanionState}
    //           selectedCompanion={selectedCompanion}
    //         />
    //       )}
    //       {mode === "LIGHT" ? (
    //         <div className="w-80 p-4 overflow-auto hidden-scroll">
    //           {!isRowClicked && !isPowerAppRoute && (
    //           <h3 style={{ color: "#032F49" }}>{selectedCompanion}</h3>
    //         )}
    //           <RoutesPage
    //             setIsRowClicked={setIsRowClicked}
    //             isRowClicked={isRowClicked}
    //           />
    //         </div>
    //       ) : (
    //         <div className="w-75 m-auto h-100  hidden-scroll">
    //           <RoutesPage setIsRowClicked={setIsRowClicked}
    //             isRowClicked={isRowClicked}/>
    //         </div>
    //       )}
    //     </div>
    // </AuthProvider><ErrorModalPopup show={isModalVisible} setShow={setIsmodal} handleModalClose={handleClose} message={modalMessage}/></>
    <>
    <AuthProvider>
      <ToastContainer position="top-center" autoClose={20000} />
      <div
        className={`h-100 ${mode === "LIGHT" ? "d-flex dark-mode" : "light-mode"}`}
      >
        {/* Render Navbar and MainSidebar together but toggle visibility */}
        <Navbar
          setMode={setMode}
          mode={mode}
          setSelectedCompanionState={setSelectedCompanionState}
          selectedCompanion={selectedCompanion}
          style={{ display: mode === "DARK" ? "block" : "none" }}
        />
        <MainSidebar
          setMode={setMode}
          mode={mode}
          setSelectedCompanionState={setSelectedCompanionState}
          selectedCompanion={selectedCompanion}
          style={{ display: mode === "DARK" ? "none" : "block" }}
        />
  
        {/* Use a single container for RoutesPage and adjust layout */}
        <div
          className={`${
            mode === "LIGHT" ? "w-80 p-4 overflow-auto" : "w-75 m-auto h-100"
          } hidden-scroll`}
        >
          {!isRowClicked && !isPowerAppRoute && mode === "LIGHT" && (
            <h3 style={{ color: "#032F49" }}>{selectedCompanion}</h3>
          )}
          <RoutesPage
            setIsRowClicked={setIsRowClicked}
            isRowClicked={isRowClicked}
          />
        </div>
      </div>
    </AuthProvider>
    <ErrorModalPopup
      show={isModalVisible}
      setShow={setIsmodal}
      handleModalClose={handleClose}
      message={modalMessage}
    />
  </>
  

  );
}

export default App;
