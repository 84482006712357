import { useEffect, useRef, useState } from "react";
import { Spinner } from "../../../web-workflow/common/Helper";

const InfiniteScroll = ({
  children,
  className,
  loadMore,
  hasData,
  isLoading,
  isTopScroll = false,
}) => {
  const divRef = useRef();
  const [lastScrollLeft, setLastScrollLeft] = useState(0);
  useEffect(() => {
    const handleScroll = (e) => {
      const { scrollHeight, scrollTop, clientHeight, scrollLeft  } = divRef.current;
      if (scrollLeft !== lastScrollLeft) {
        setLastScrollLeft(scrollLeft);  
        return;  
      }
      if (!hasData || isLoading) return;

      if (isTopScroll) {
        // If it's top scrolling, load more if scrolled to top
        if (scrollTop === 0) {
          loadMore();
        }
        return;
      }


      if (scrollTop + clientHeight >= scrollHeight - 1) {
        loadMore();
      }
    };
    if (divRef.current) {
      divRef?.current?.addEventListener("scroll", handleScroll);
    }
    return () => {
      if (divRef.current) {
        divRef?.current?.removeEventListener("scroll", handleScroll);
      }
    };
  }, [divRef.current, hasData, isLoading, isTopScroll, loadMore, lastScrollLeft]);
  return (
    <div ref={divRef} className={className}>
      {isTopScroll && isLoading && <Spinner />}
      {children}
      {!isTopScroll && (
        <NoMoreDataComponent isLoading={isLoading} hasData={hasData} />
      )}
    </div>
  );
};
export default InfiniteScroll;

export const NoMoreDataComponent = ({ isLoading, hasData }) => {
  const [message, setMessage] = useState("No more Data");
  return (
    <>
      {isLoading && (
        <div>
          <Spinner />
        </div>
      )}
      {!hasData && <div className="text-danger" style={{paddingLeft:'10px'}}>{message}</div>}
    </>
  );
};
