import React from "react";
import "./AssignedToModal.css";
import { Spinner } from "../../common/Helper";

const AssignedToModal = ({
  handleAssignReason,
  addAssignReason,
  setAddAssignReason,
  cancelAssignReason,
  
}) => {

  return (
    <div className="assign-reason-modal-overlay-fade z-3">
      <div className="assign-reason-modal-content-section">
         <div className="assign-reason-modal-content-header">
          <p>Add Specific Reason</p>
        </div>
        <div className="assign-reason-modal-content-body">
        <div className="assign-reason-textarea">
          <textarea 
            className="assign-search-textarea"
            type="text"
            rows="4"
            value={addAssignReason}
            onChange={(e) => setAddAssignReason(e.target.value)}
            placeholder="Add the reason"/>
        </div>
        </div>
        < div className="assign-reason-actions-btn">
          <button className="assign-reason-btn-cancel" onClick={cancelAssignReason}>
            Cancel
          </button>
          <button className="assign-reason-btn-save" onClick={handleAssignReason}>
            Ok
          </button>
        </div>
      </div>
    </div>
  );
};

export default AssignedToModal;