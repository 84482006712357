import React, { useEffect, useState, useCallback } from "react";
import DatePicker from "react-datepicker";
import { fileTypes, prorityValues, statusTypes } from "./config";
import { getfilteroptionsdata, getUsers } from "../../api/Service";
import { formatCalenderDate } from "../../utils/util";
import { Accordion } from "react-bootstrap";
import _, { debounce } from "lodash";
import userSearchIcon from "../../assests/search-icon.png"
import InfiniteScroll from "../../../codercompanion/components/infinite-scroll";

const currentDate = formatCalenderDate(new Date());
const dateFormat = "yyyy-MM-dd"


const WorkFlowFilters = ({ showModal, onApply, onCancel, filtersObj, setFiltersObj, openAccordion, setOpenAccordion }) => {
  // const permissions = JSON.parse(sessionStorage.getItem("permissions"));
  const permissions = JSON.parse(sessionStorage.getItem("permissions"));
  const superUserReadOnly = permissions?.super_user_readonly || false;
  const superUser = permissions?.super_user || false;
  const admin = permissions?.admin || false;
  let PrivilegedUser = ""
  if ("Privileged User" in permissions) {
    PrivilegedUser =  permissions["Privileged User"];
  } else {
    PrivilegedUser = false;
  }
  const userID = JSON.parse(sessionStorage.getItem("userId"));
  const workflow = permissions && Array.isArray(permissions.WorkflowCompanion) ? permissions.WorkflowCompanion[0] : "";
  const workflowReadOnly = permissions && Array.isArray(permissions.WorkflowCompanion) ? permissions.WorkflowCompanion[0] : "";

  const adminUser = permissions && permissions.admin;
    
  const [coderNames, setCoderNames] = useState([]);
  const [insurerIdentifierList, setInsurerIdentifierList] = useState([]);
  const [toggleUnitConversion, setToggleUnitConversion] = useState(false);
  const [getAllAssineByUsers, setGetAllAssineByUsers] = useState([]);
  const [getAllAssineToUsers, setGetAllAssineToUsers] = useState([]);
  const [searchFilterAssgineeByUserName, setSearchFilterAssgineeByUserName] = useState("");
  const [searchFilterAssgineeToUserName, setSearchFilterAssgineeToUserName] = useState("");
  const [loadingUsers, setLoadingUsers] = useState(false);
  const [assgineFilterByCurrentPage, setAssgineFilterByCurrentPage ] = useState(1);
  const [assgineFilterToCurrentPage, setAssgineFilterToCurrentPage ] = useState(1);
  const [specialityNames, setSpecialityNames] = useState([]);
  const [hasMoreAssgineByUsers, setHasMoreAssgineByUsers] = useState(true);
  const [hasMoreAssgineToUsers, setHasMoreAssgineToUsers] = useState(true);
  // const [filtersObj, setFiltersObj] = useState({
  //   fileType: [],
  //   priorityValue: [],
  //   status: [],
  //   insurerIdentifier: [],
  //   codedBy: [],
   // specifications: [],
    // assignBy: [],
    // assignTo: [],
  //   fromAdmissionDate: "",
  //   toAdmissionDate: "",
  //   fromSeparationDate: "",
  //   toSeparationDate: "",
  // });
  const handleCheckboxChange = (name, value) => {
    const currentIndex = filtersObj[name]?.indexOf(value);
    const newChecked = [...filtersObj[name]];
    if (currentIndex === -1) {
      newChecked.push(value);
    } else {
      newChecked.splice(currentIndex, 1);
    }
    setFiltersObj({...filtersObj, [name]: newChecked});

    if (openAccordion === null) {
      setOpenAccordion(name);
    }
  };
  const handleFiltersApply = () => {
    const filters = {
        insurer_identifier: filtersObj.insurerIdentifier,
        priority_value: filtersObj.priorityValue,
        status: filtersObj.status,
        file_type: filtersObj.fileType,
        coder_name: filtersObj.codedBy,
         // ...(admin || superUser || superUserReadOnly  && { assigned_to: filtersObj.assigned_to }),
         ...( (admin || superUser || superUserReadOnly) && { assigned_to: filtersObj.assigned_to }),
         assigned_by: filtersObj.assigned_by,
         case_mix_speciality: filtersObj.case_mix_speciality,
         length_of_stay: filtersObj.length_of_stay,
        admission_date_from: formatCalenderDate(filtersObj.fromAdmissionDate),
        admission_date_to: filtersObj.fromAdmissionDate
          ? filtersObj.toAdmissionDate
            ? formatCalenderDate(filtersObj.toAdmissionDate)
            : currentDate
          : "",
        separation_date_from: formatCalenderDate(filtersObj.fromSeparationDate),
        separation_date_to: filtersObj.fromSeparationDate
          ? filtersObj.toSeparationDate
            ? formatCalenderDate(filtersObj.toSeparationDate)
            : currentDate
          : "",
      };
    onApply(filters);
    setAssgineFilterToCurrentPage(1);
    setGetAllAssineToUsers([]);
    setAssgineFilterByCurrentPage(1);
    setGetAllAssineByUsers([]);
    // setOpenAccordion(null);
    onCancel();
  };

  const handleDateChange = (name, date) => {
    if(name === "fromAdmissionDate" && !date){
      setFiltersObj({admission_date_from: null})
      return; 
    }
    if(name==="fromSeparationDate" && !date){
      setFiltersObj({separation_date_from: null})
      return;
    }
    if(name ==="length_of_stay"){
      setFiltersObj({ ...filtersObj, [name]: date})
    }else{
      setFiltersObj({...filtersObj, [name]: date})
    }
  };

  useEffect(() => {
    getfilteroptionsdata().then((res) => {
        const { coder_name, insurer_identifier, speciality } = res.data;
      //console.log(res.data,res,'Shell...',coder_name, insurer_identifier);
      const formatedCoderNames = coder_name.length>0 && coder_name.map((name, i) => ({
        id: i + 1,
        name: name[0].trim(),
      }));
      const formatedSpecialityNames = speciality.length > 0 && speciality.map((name, i) => ({
        id: i + 1,
        name: name[0].trim(),
      }));
      const formattedInsurerIdentifier = insurer_identifier.map((name, i) => ({
        id: i + 1,
        name: name[0],
      }));
      setCoderNames(formatedCoderNames);
      setSpecialityNames(formatedSpecialityNames);
      setInsurerIdentifierList(formattedInsurerIdentifier);
    }).catch((error)=>{
        if(error.response && (error.response.status===401 || error.response.status===403)){
          let errorMessage='';
          if(error.response.status===401){
            errorMessage='Please login again'
          }else if(error.response.status===403){
            errorMessage='You do not have permission to access this resource'
          }
          window.dispatchEvent(new CustomEvent('tokenExpired', {detail:{errorMessage}}));
        }
    });
  }, []);

  useEffect(() => {
    // USers API
    if(showModal) {
      document.body.style.overflowY = "hidden";
      getMoreAssigneToUsers(1, true);
      getMoreAssigneByUsers(1, true);
    }
  }, [showModal])

  const handleUnitCoversionToggle =() =>{
    setToggleUnitConversion(!toggleUnitConversion)
  }

  const fetchFilterByUsers = useCallback(debounce((userName) => {
    setLoadingUsers(true);
    getUsers(userName, assgineFilterByCurrentPage)
      .then((res) => {
        if(res.message){
          // toast.error(res.message);
        }
        const { users } = res.data;
        setGetAllAssineByUsers(users);
      })
      .catch((err) =>{
        console.log("No Users Found::", err.message)
        setGetAllAssineByUsers([]);
      }
      )
      .finally(() => setLoadingUsers(false));
  }, 500),
  []
);

const fetchFilterToUsers = useCallback(debounce((userName) => {
  setLoadingUsers(true);
  getUsers(userName, assgineFilterToCurrentPage)
    .then((res) => {
      if(res.message){
        // toast.error(res.message);
      }
      const { users } = res.data;
      setGetAllAssineToUsers(users);
    })
    .catch((err) =>{
      console.log("No Users Found::", err.message)
      setGetAllAssineToUsers([]);
    }
    )
    .finally(() => setLoadingUsers(false));
}, 500),
[]
);

const getMoreAssigneByUsers = async(assgineFilterByCurrentPage, load_more) => {
  try {
    setLoadingUsers(true);
    const response = await getUsers(searchFilterAssgineeByUserName, assgineFilterByCurrentPage);
    const { users } = response.data;
    const mergedUsers = [
      ...new Map([...getAllAssineByUsers, ...users].map(item => [item.user_id, item])).values()
    ];
    if (load_more) {
      setGetAllAssineByUsers(mergedUsers);
    } else {
      setGetAllAssineByUsers(users);
    }
    setHasMoreAssgineByUsers(true);
    const newPage = assgineFilterByCurrentPage + 1;
    setAssgineFilterByCurrentPage(newPage);
  } catch (error) {
    console.log("Error fetching users:", error);
    setHasMoreAssgineByUsers(false);
    setAssgineFilterByCurrentPage(1);
    if (!load_more) {
      setGetAllAssineByUsers([]);
    }
  } finally {
    setLoadingUsers(false);
  }
};

const getMoreAssigneToUsers = async(assgineFilterToCurrentPage, load_more) => {
  try {
    setLoadingUsers(true);
    const response = await getUsers(searchFilterAssgineeToUserName, assgineFilterToCurrentPage);
    const { users } = response.data;
    const mergedUsers = [
      ...new Map([...getAllAssineToUsers, ...users].map(item => [item.user_id, item])).values()
    ];
    if (load_more) {
      setGetAllAssineToUsers(mergedUsers);
    } else {
      setGetAllAssineToUsers(users);
    }
    setHasMoreAssgineToUsers(true);
    const newPage = assgineFilterToCurrentPage + 1;
    setAssgineFilterToCurrentPage(newPage);
  } catch (error) {
    console.log("Error fetching users:", error);
    setHasMoreAssgineToUsers(false);
    setAssgineFilterToCurrentPage(1)
    if (!load_more) {
      setGetAllAssineByUsers([]);
    }
  } finally {
    setLoadingUsers(false);
  }
};

const searchAssgineFilterByUsers = (event) => {
  const {value} = event.target
  setSearchFilterAssgineeByUserName(value);
  fetchFilterByUsers(value);
};

const searchAssgineFilterToUsers = (event) => {
  const {value} = event.target
  console.log(value, 'claue')
  setSearchFilterAssgineeToUserName(value);
  fetchFilterToUsers(value);
};

const toggleAccordion = (id) => {
  if(id === "7"){
    getMoreAssigneByUsers(1, true);
    setGetAllAssineToUsers([]);
    setHasMoreAssgineToUsers(true);
    setSearchFilterAssgineeByUserName("");
    getMoreAssigneByUsers(1, true);
    if(searchFilterAssgineeByUserName.length >= 0){
        getMoreAssigneByUsers(1, true);
    }
  }
  if(id === "8"){
    getMoreAssigneToUsers(1, true);
    setGetAllAssineByUsers([]);
    setHasMoreAssgineByUsers(true);
    setSearchFilterAssgineeToUserName("")
    if(searchFilterAssgineeToUserName.length >= 0){
      getMoreAssigneToUsers(1, true);
  }
  }
  setOpenAccordion((prevOpenAccordion) => {
    return prevOpenAccordion === id ? null : id;
  });
};
  return (
    <div
    className="modalfilters"
    style={{ display: showModal ? "block" : "none", overflow: "auto"}}
    tabIndex="-1"
  >
    <div
        className="modalfilters-section"
    >
      <div
        className="modalfilters-content"
        style={{ width: "900px", margin: "auto", background: "white", borderRadius: "5px", position: "relative"}}
      >
        <div className="filters-modal-header">
        <h3 className="filter-title">Filter</h3>
        <button className="btn-close" 
            onClick={() => {
              onCancel();
              setAssgineFilterToCurrentPage(1);
              setAssgineFilterByCurrentPage(1);
            }}
            ></button>
        </div>

        <div className="filter-modal-body">
          <div className="d-flex">
            {/* File Type */}
            <div className="left-filter">
              {/* File Type */}
              <Accordion defaultActiveKey={null} flush className="filters-border">
                <Accordion.Item eventKey="1" className="item_accordion" onClick={() => toggleAccordion("1")} active={openAccordion === "1"}>
                  <Accordion.Header className="header_accordion">File Type</Accordion.Header>
                  {fileTypes.length > 0 ? fileTypes.map((fileType) => (
                    <Accordion.Body key={fileType} className="body_accordion">
                      <input
                        className="accordion-input"
                        type="checkbox"
                        id="fileType1"
                        onChange={() =>
                        handleCheckboxChange("fileType", fileType)}
                        checked={filtersObj.fileType?.includes(fileType)}
                      />
                      <label className="accordion-label" htmlFor="fileType1">{fileType}</label>
                    </Accordion.Body>
                  )): 
                  <Accordion.Body className="body_accordion">No Data Found</Accordion.Body>
                  }
                </Accordion.Item>
              {/* Priority Value */}
                <Accordion.Item eventKey="2" className="item_accordion" onClick={() => toggleAccordion("2")} active={openAccordion === "2"}>
                  <Accordion.Header className="header_accordion">Priority Value</Accordion.Header>
                    <Accordion.Body className="">
                    <button className="toggle-conversion-btn" onClick={handleUnitCoversionToggle}>$ / NWAU</button>
                    { prorityValues.length > 0 ? prorityValues.map((priority) => (
                      <Accordion.Body key={priority.label} className="body_accordion1">
                        <input
                        type="checkbox"
                        className="accordion-input"
                        id={`priority${priority.value}`}
                        onChange={() =>handleCheckboxChange("priorityValue", priority.value)}
                        checked={filtersObj.priorityValue?.includes(priority.value)}
                        />
                        <label className="accordion-label" htmlFor={`priority${priority.value}`}>
                          {toggleUnitConversion? priority.label:priority.nwunits || priority.label}
                        </label>
                      </Accordion.Body>
                    )): 
                    <Accordion.Body>No Data Found</Accordion.Body>
                    }
                    </Accordion.Body>
                </Accordion.Item>
              {/* Status */}
                <Accordion.Item eventKey="3" className="item_accordion" onClick={() => toggleAccordion("3")} active={openAccordion === "3"}>
                  <Accordion.Header className="header_accordion">Status</Accordion.Header>
                    {statusTypes.length > 0 ? statusTypes.map((type) => (
                      <Accordion.Body key={type} className="body_accordion">
                      <input
                        type="checkbox"
                        className="accordion-input"
                        id={`statusType-${type}`}
                        onChange={() => handleCheckboxChange("status", type)}
                        checked={filtersObj.status?.includes(type)}
                      />
                       <label className="accordion-label" htmlFor={`statusType-${type}`}>{type}</label>
                       </Accordion.Body>
                    )) : 
                    <Accordion.Body>No Data Found</Accordion.Body>
                    }
                </Accordion.Item>
              {/* Coded By */}
                <Accordion.Item eventKey="4" className="item_accordion" onClick={() => toggleAccordion("4")} active={openAccordion === "4"}>
                  <Accordion.Header className="header_accordion">Coded By</Accordion.Header>
                  {coderNames.length > 0 ? coderNames?.map((item) => (
                    <Accordion.Body key={item.id} className="body_accordion">
                      <input
                        type="checkbox"
                        className="accordion-input"
                        id={`codedby-${item.id}`}
                        value={item.id}
                        checked={filtersObj.codedBy?.includes(item.name)}
                        onChange={(e) =>
                          handleCheckboxChange("codedBy", item.name)
                        }
                      />
                      <label className="accordion-label" htmlFor={`codedby-${item.id}`}style={{ marginLeft: "8px" }}>{item.name}</label>
                    </Accordion.Body>
                  )):
                  <Accordion.Body className="body_accordion">No Data Found</Accordion.Body>
                  }
                </Accordion.Item>
              {/* Insurer Identifier */}
                <Accordion.Item eventKey="5" className="item_accordion" onClick={() => toggleAccordion("5")} active={openAccordion === "5"}>
                  <Accordion.Header className="header_accordion">Insurer Identifier</Accordion.Header>
                    {insurerIdentifierList.length > 0 ? insurerIdentifierList.map((item) => (
                      <Accordion.Body key={item.id} className="body_accordion">
                        <input
                          type="checkbox"
                          className="accordion-input"
                          id={`insurer-${item.id}`}
                          value={item.id}
                          checked={filtersObj.insurerIdentifier?.includes(item.name)}
                          onChange={(e) =>handleCheckboxChange("insurerIdentifier", item.name)}
                        />
                        <label className="accordion-label" htmlFor={`insurer-${item.id}`}style={{ marginLeft: "8px" }}>{item.name}</label>
                      </Accordion.Body>
                    )) : 
                      <Accordion.Body>No Data Found</Accordion.Body>
                    }    
                </Accordion.Item>
              {/* specifications */}
                <Accordion.Item eventKey="6" className="item_accordion" onClick={() => toggleAccordion("6")} active={openAccordion === "6"}>
                  <Accordion.Header className="header_accordion">Case Mix Speciality</Accordion.Header>
                  {specialityNames.length > 0 ? specialityNames.map((item) => (
                    <Accordion.Body key={item.name} className="body_accordion">
                      <input
                        type="checkbox"
                        className="accordion-input"
                        id={`case_mix_speciality-${item.name}`}
                        onChange={() => handleCheckboxChange("case_mix_speciality", item.name)}
                        checked={filtersObj.case_mix_speciality?.includes(item.name)}
                      />
                      <label className="accordion-label" htmlFor={`case-${item.name}`}>{item.name}</label>
                    </Accordion.Body>
                  )) : <Accordion.Body>No Data Found</Accordion.Body>}
                </Accordion.Item>
              {/* assign By */}
                <Accordion.Item eventKey="7" className="item_accordion_user" onClick={() => toggleAccordion("7")} active={openAccordion === "7"}>
                  <Accordion.Header className="header_accordion">Assign By</Accordion.Header>
                  <Accordion.Body className="">
                  <div className="assigne-search-filter-section">
                    <img className="assigne-search-icon" src={userSearchIcon} alt={"userSearchIcon"} />
                    <input
                      className="assgine-search-filter-input"
                      type="text"
                      value={searchFilterAssgineeByUserName}
                      onChange={searchAssgineFilterByUsers}
                      placeholder="Search by Username"
                    />
                  </div>
                  <InfiniteScroll
                    className="assgine-modal-body"
                    hasData={hasMoreAssgineByUsers}
                    loadMore={() => getMoreAssigneByUsers(assgineFilterByCurrentPage, true)}
                    isLoading={loadingUsers}
                  >
                  {getAllAssineByUsers?.length > 0 ? getAllAssineByUsers?.map((item) => (
                    <Accordion.Body key={item?.user_id} className="body_accordion1">
                      <input
                        type="checkbox"
                        className="accordion-input"
                        id={`assigned_by-${item?.username}`}
                        onChange={() => handleCheckboxChange("assigned_by", item?.user_id)}
                        checked={filtersObj.assigned_by?.includes(item?.user_id)}
                      />
                      <label className="accordion-label" htmlFor={`assigned_by-${item?.username}`}>{item?.username}</label>
                    </Accordion.Body>
                  )) : <Accordion.Body>No Data Found</Accordion.Body>}
                  </InfiniteScroll>
                  </Accordion.Body>
                </Accordion.Item>
              {/* Assign To */}
                {/* {(admin || superUser || superUserReadOnly) && (
                  <Accordion.Item eventKey="8" className="item_accordion_user" onClick={() => toggleAccordion("8")} active={openAccordion === "8"}>
                  <Accordion.Header className="header_accordion">Assign To</Accordion.Header>
                  <Accordion.Body className="">
                  <div className="assigne-search-filter-section">
                    <img className="assigne-search-icon" src={userSearchIcon} alt={"userSearchIcon"} />
                    <input
                      className="assgine-search-filter-input"
                      type="text"
                      value={searchFilterAssgineeToUserName}
                      onChange={searchAssgineFilterToUsers}
                      placeholder="Search by Username"
                    />
                  </div>
                  <InfiniteScroll
                    className="assgine-modal-body"
                    hasData={hasMoreUsers}
                    loadMore={() => getMoreAssigneToUsers(assgineFilterToCurrentPage, true)}
                    isLoading={loadingUsers}
                  >
                    
                  {getAllAssineToUsers?.length > 0 ? getAllAssineToUsers?.map((item) => (
                    <Accordion.Body key={item?.user_id} className="body_accordion1">
                      <input
                        type="checkbox"
                        className="accordion-input"
                        id={`assigned_to-${item?.username}`}
                        onChange={() => handleCheckboxChange("assigned_to", item?.user_id)}
                        checked={filtersObj.assigned_to?.includes(item?.user_id)}
                      />
                      <label className="accordion-label" htmlFor={`assigned_to-${item?.username}`}>{item?.username}</label>
                    </Accordion.Body>
                  )): <Accordion.Body>No Data Found</Accordion.Body>}
                  </InfiniteScroll>
                  </Accordion.Body>
                  </Accordion.Item>
                )} */}
                
               {(admin || superUser || superUserReadOnly) &&  <Accordion.Item eventKey="8" className="item_accordion_user" onClick={() => toggleAccordion("8")} active={openAccordion === "8"}>
                  <Accordion.Header className="header_accordion">Assign To</Accordion.Header>
                  <Accordion.Body className="">
                  <div className="assigne-search-filter-section">
                    <img className="assigne-search-icon" src={userSearchIcon} alt={"userSearchIcon"} />
                    <input
                      className="assgine-search-filter-input"
                      type="text"
                      value={searchFilterAssgineeToUserName}
                      onChange={searchAssgineFilterToUsers}
                      placeholder="Search by Username"
                    />
                  </div>
                  <InfiniteScroll
                    className="assgine-modal-body"
                    hasData={hasMoreAssgineToUsers}
                    loadMore={() => getMoreAssigneToUsers(assgineFilterToCurrentPage, true)}
                    isLoading={loadingUsers}
                  >
                    
                  {getAllAssineToUsers?.length > 0 ? getAllAssineToUsers?.map((item) => (
                    <Accordion.Body key={item?.user_id} className="body_accordion1">
                      <input
                        type="checkbox"
                        className="accordion-input"
                        id={`assigned_to-${item?.username}`}
                        onChange={() => handleCheckboxChange("assigned_to", item?.user_id)}
                        checked={filtersObj.assigned_to?.includes(item?.user_id)}
                      />
                      <label className="accordion-label" htmlFor={`assigned_to-${item?.username}`}>{item?.username}</label>
                    </Accordion.Body>
                  )): <Accordion.Body>No Data Found</Accordion.Body>}
                  </InfiniteScroll>
                  </Accordion.Body>
                  </Accordion.Item>
                }
              </Accordion>
            </div>

            {/* Dates */}
            <div className="dates-wrapper">
              <h6 className="text-start">Admission Date:</h6>
              <div className="date-picker-wrapper">
                <DatePicker
                  selected={filtersObj.fromAdmissionDate}
                  onChange={(dt) => handleDateChange("fromAdmissionDate", dt)}
                  dateFormat={dateFormat}
                  placeholderText="From"
                  maxDate={currentDate}
                />
                 <div
                   className = {`${!filtersObj.fromAdmissionDate ? "datepicker-tooltip" : ""}`}
                   data-title={"'To date' cannot be selected until 'From date' is selected"}
                 >
                <DatePicker
                  selected={filtersObj.toAdmissionDate}
                  onChange={(dt) => handleDateChange("toAdmissionDate", dt)}
                  dateFormat={dateFormat}
                  placeholderText="To"
                  disabled={!filtersObj.fromAdmissionDate}
                  minDate={filtersObj.fromAdmissionDate}
                  maxDate={currentDate}
                  />
                </div>
              </div>
              <h6 className="text-start date-header">Separation Date:</h6>
              <div className="date-picker-wrapper">
                <DatePicker
                  selected={filtersObj.fromSeparationDate}
                  onChange={(dt) => handleDateChange("fromSeparationDate", dt)}
                  dateFormat={dateFormat}
                  placeholderText="From"
                  minDate={filtersObj.fromAdmissionDate}
                  maxDate={currentDate}
                />
                <div
                 className = {`${!filtersObj.fromSeparationDate ? "datepicker-tooltip" : ""}`}
                 data-title={"'To date' cannot be selected until 'From date' is selected"}
                >
                <DatePicker
                  selected={filtersObj.toSeparationDate}
                  onChange={(dt) => handleDateChange("toSeparationDate", dt)}
                  dateFormat={dateFormat}
                  placeholderText="To"
                  disabled={!filtersObj.fromSeparationDate}
                  minDate={filtersObj.fromSeparationDate}
                  maxDate={currentDate}
                  />
                </div>
              </div>
              <div>
              <h6 className="text-start date-header">LOS</h6>
              <div className="">
              <input 
                type="number"
                className="filter-input"
                value={filtersObj.length_of_stay || ""}
                placeholder="Los"
                onChange={(e) => {
                const newValue = e.target.value === "" || isNaN(e.target.value) ? [] : [Number(e.target.value)];
                handleDateChange("length_of_stay", newValue);
                }}
              />
              </div>
              </div>
            </div>
          </div>
        </div>
        <div className="filter-footer">
          <button
            type="button"
             className="filter-btn-cancel"
            onClick={onCancel}
          >
            Close
          </button>
          <button
            type="button"
            className="filter-btn-save"
            // style={{ backgroundColor: "#4298CD", border: "none" }}
            onClick={handleFiltersApply}
          >
            {/* <span>
            <img src={filtertick} alt="Filter Tick" />
          </span>{" "} */}
            Apply
          </button>
        </div>
      </div>
    </div>
  </div>
  );
};

export default WorkFlowFilters;
 